/* BACKGROUND UTILITY STYLES
   ----------------------------- */

.bg-white {
    background: #fff!important;
}

// ::-webkit-scrollbar {
//     display: none;
// }

