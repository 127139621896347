/* GLOBAL STYLES
   ----------------------------- */

body {
  background-color: $globalBackgroundColor;
  font-family: $globalFontFamily;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border-color: $hrColor;
}

.modal-new-agents {
  max-width: 63% !important;
}

.search {
  display: flex;
  justify-content: flex-end;
  padding: 28px;
  color: #3b5998;
}

.close {
  display: flex;
  justify-content: flex-end;
  padding: 28px;
  color: #eb4c4b;
}

.close:hover {
  color: #cf2d2c;
}

.form-control-one > input {
  width: 18%;
}

.form-control-one .container-group {
  width: 18% !important;
}

#primary-content > div > div > div > div:nth-child(3) {
  padding: 21px;
}

#primary-content > div > div > div > div:nth-child(1) > button {
  width: 10%;
}

.container-button {
  display: flex;
  justify-content: space-between;
}

.form-control-one-agents {
  display: flex;
  justify-content: center;
  padding: 2%;
}

.form-control-two-agents {
  display: flex;
  padding: 2%;
}

.form-agents-one {
  width: 78%;
  margin-right: 28px;
}

#primary-content
  > div
  > div
  > div
  > div:nth-child(1)
  > div.container-search
  > div
  > div.form-control-two-agents
  > input {
  width: 87% !important;
  margin-right: 2%;
}

.action-td {
  color: #5b6cde;
}

.style-id {
  font-weight: bold;
  font-size: 14px;
}

.action {
  text-align: center !important;
  font-size: 14px;
}

/*.table {
    border: 1px solid #dee2e6;
}*/

.table > thead {
  background-color: #4c84ff;
  color: #ffff;
}

.table > thead > tr > th {
  font-weight: 600;
}

.table2 {
  // width: 200px;
  height: 600px !important;
  overflow: scroll;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table2 th,
.table2 td {
  padding: 0.25rem;
  vertical-align: center;
  text-align: center;
  border-top: 1px solid #dee2e6;
}
.table2 thead th {
  vertical-align: center;
  text-align: center;
  border-bottom: 2px solid #dee2e6;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #4c84ff;
}
.table2 tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table3 {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table3 th,
.table3 td {
  padding: 0.25rem;
  vertical-align: center;
  text-align: center;
  border-top: 1px solid #dee2e6;
}
.table3 thead th {
  vertical-align: center;
  text-align: center;
  border-bottom: 2px solid #dee2e6;
}
.table3 tbody + tbody {
  border-top: 2px solid #dee2e6;
}

@media screen and (min-width: 992px) {
  .table2 {
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
    color: #212529;
  }
  .table2 th,
  .table2 td {
    padding: 0.25rem;
    vertical-align: center;
    text-align: center;
    border-top: 1px solid #dee2e6;
  }
  .table2 thead th {
    vertical-align: center;
    text-align: center;
    border-bottom: 2px solid #dee2e6;
  }
  .table2 tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
}
.pagination {
  padding-top: 2%;
  display: flex;
  justify-content: flex-end;
}

#primary-content
  > div
  > div
  > div.tab-pane.active
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div
  > table
  > tbody
  > tr
  > td:nth-child(4)
  > div {
  width: fit-content;
  border: none !important;
}

.container-item-8 .card {
  width: fit-content;
  border: none !important;
}
.container-item {
  cursor: pointer;
  text-align: center !important;

  justify-self: center;
}

.trash {
  color: #cf2d2c;
}

.bg-danger2 {
  background-color: #fe5461 !important;
}

#primary-content
  > div
  > div
  > div.tab-pane.active
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div
  > table
  > tbody
  > tr
  > td:nth-child(4) {
  display: flex;
  justify-content: space-around;
}

#primary-content
  > div
  > div
  > div.tab-pane.active
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td.container-item-8 {
  display: flex;
  justify-content: space-around;
}

#primary-content > div > div > div.tab-pane.active > div > div > div > div > div > div > div:nth-child(2) {
  width: 100%;
}

#primary-content > div > div > div > div.card > div > table > tbody > tr > td.container-item-4 > div {
  display: flex;
  justify-content: center;
}

.td-audit > td {
  vertical-align: inherit !important;
}

.form-check-input {
  position: unset !important;
  margin-left: unset !important;
}

.button-create-commmission {
  width: 13% !important;
}

#primary-content > div > div > div.tab-pane.active > div > div > div > div > div > div > div:nth-child(1) {
  padding: 1%;
  border: none;
}

.container-login {
  height: 100vh;
  background-image: url('../../../assets/images/tropical.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-secondari-login {
  width: 25%;
  height: 40%;
  text-align: center;
  padding-bottom: 44% !important;
}

.container-logo-login {
  width: 80%;
}

.container-input {
  padding-top: 15%;
  padding: 10%;
}

.button-price-list {
  padding: 13px;
  display: flex;
  justify-content: space-around;
  width: 22%;
}

.container-list-total {
  padding: 2%;
  display: flex;
  justify-content: space-around;
}

.container-button-load-delete {
  padding: 2%;
  justify-content: space-around;
  width: 28%;
  display: flex;
}

.container-button-load-delete > input {
  width: 66%;
  padding: 2%;
}

.container-button-load-delete > input.btn.btn-danger {
  margin-right: 6px;
}

.card-select-vat {
  height: 500px;
}

.card-img-top {
  width: 100px;
  border-radius: 50%;
  height: 100px;
}
.delete {
  cursor: pointer;
}

.banderas {
  display: flex;
}

.container-text {
  width: 50%;
}

.container-bandera {
  width: 50%;
  text-align: -webkit-right;
}

#primary-content > div > div > div.tab-pane.active > div > div > div > div > div > div > div:nth-child(9) > input {
  width: 20%;
}

.table thead th {
  border: none;
  padding: 18px;
  font-size: 14px;
  font-weight: 500;
}

.container-table-all {
  display: flex;
  justify-content: center;
}

.table-striped {
  width: 94%;
}

.container-input-modal {
  border-bottom: 1px solid #e5e9f2;
  padding: 2%;
}

.container-button > h3 {
  font-weight: 600;
  font-size: 1.25125rem;
  margin-top: 1%;
}

.title-table {
  color: #1b223c;
  font-size: 1.63rem;
  font-weight: 600;
  padding: 2%;
}

.container-search {
  display: flex;
  justify-content: flex-end;
  padding-top: 4%;
}

.top-information {
  width: 96%;
}

.container-search-user > .top-information {
  width: 93%;
}

.container-search-supplier > .top-information {
  padding-bottom: 4%;
}

.form-check-input {
  display: block;
  position: relative;
  padding-left: 1.56rem;
  cursor: pointer;
}

.form-check-inline {
  width: 85%;
  display: flex;
}

.form-check-label {
  font-weight: 600;
}

.pagination {
  padding: 3% !important;
}

#primary-content > div > div > div > div > div.container-search > div > div.col-sm-3 > div > div > label:nth-child(2) {
  margin-right: 20%;
}

.form-control::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b0adad;
}

.form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b0adad;
  opacity: 1 /* esto es porque Firefox le reduce la opacidad por defecto */;
}

.form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b0adad;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b0adad;
}

.container-toggle-sidebar {
  width: 6%;
  height: 68px;
  border-right: #ebecef solid 1px;
}

.toggle-sidebar {
  height: inherit;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -25px;
}

.fa-edit {
  color: #4c84ff !important;
}

.navbar-expand-md {
  background-color: #fff;
}

.fa-align-left {
  color: #8a909d;
}

.site-logo-bar {
  height: 8.3% !important;
  padding-top: 4%;
  background-color: #4c84ff !important;
}

.site-logo-bar .navbar-brand > img {
  max-height: 200px !important;
  max-width: 200px !important;
  // width: 100vh !important;
}

#app > div > div > div:nth-child(1) > div > nav {
  background-color: #212a39 !important;
}

.separator {
  display: none !important;
}

.nav-item {
  align-items: center !important;
}

.main-menu {
  // padding-top: 19% !important;
}

.nav-item > a {
  color: #a6aab4 !important;
  align-items: center !important;
}

#main-menu .nav-item > a.active {
  color: #fff !important;
}

.btn-outline-primary {
  font-size: 13px !important;
  // color: #4285f4 !important;
}

.btn-outline-primary {
  font-weight: 500;
}

.btn-outline-primary:hover {
  background-color: #4285f4 !important;
  color: #ffff !important;
}

.btn-outline-orange {
  color: #f29100;
  border-color: #f29100;
}
.btn-outline-orange:hover {
  color: #ffffff;
  background-color: #f29100;
  border-color: #f29100;
}
.btn-outline-orange:focus,
.btn-outline-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 84, 97, 0.5);
}
.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #f29100;
  background-color: transparent;
}
.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #ffffff;
  background-color: #f29100;
  border-color: #f29100;
}
.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 84, 97, 0.5);
}

.newBlue {
  background-color: #00bc8c !important;
  border-color: white;
}

.btn-orange {
  color: #ffffff;
  background-color: #f29100;
  border-color: #f29100;
}
.btn-orange:hover {
  color: #ffffff;
  background-color: #f27f21;
  border-color: #f28c30;
}
.btn-orange:focus,
.btn-orange.focus {
  color: #ffffff;
  background-color: #fe2e3e;
  border-color: #fe2132;
  box-shadow: 0 0 0 0.2rem rgba(254, 110, 121, 0.5);
}
.btn-orange.disabled,
.btn-orange:disabled {
  color: #ffffff;
  background-color: #f29100;
  border-color: #f29100;
}
.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #ffffff;
  background-color: #fe2132;
  border-color: #fe1526;
}
.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 110, 121, 0.5);
}

.bg-primary2 {
  background-color: #4c84ff !important;
}
.btn-primary {
  background-color: #4c84ff !important;
}
.border-primary2 {
  border-color: #4c84ff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1961ff !important;
}

.mdi {
  width: 29px !important;
}

.mdi-square-edit-outline {
  color: #4c84ff !important;
}

.nav-item-label {
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 89%;
  font-weight: bold;
}

#main-menu .nav-item > a.active > i {
  color: #4c84ff !important;
}

.menu-expand-icon {
  width: 6% !important;
}

.col-principal {
  font-weight: bold;
}

.pagination .page-item.active .page-link {
  background-color: #4285f4 !important;
}

.container-search-agents {
  width: 98%;
  padding-bottom: 3%;
}

.container-search-customer-price {
  width: 30%;
  padding-left: 2%;
  padding-top: 2%;
  font-weight: 600;
}

.inline-agents {
  justify-content: center;
}

.inline-agents > label {
  padding-right: 7%;
}

#primary-content > div > div > div > div.container-search > div > div.col-sm-3 > div > div > label:nth-child(2) {
  padding-right: 19%;
}

.control-indicator {
  display: flex;
  padding: 1%;
  color: #1b223c;
}

.control-indicator > input {
  margin-right: 3% !important;
}

h3 {
  color: #001775;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.2;
  font-size: 21px;
}

.form-group label {
  color: #1b223c;
  font-size: 0.98rem;
}

.card-header-border-bottom {
  border-bottom: solid 1px #dedede;
}

.modal-title > h5 {
  font-weight: 600;
}

.border-top {
  margin-bottom: 2%;
}

.bottom-margin-row {
  margin-bottom: 3%;
}

.container-title-tabs {
  width: 100%;
  height: 75px;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f7f7f7;
  border: 1px solid #e5e9f2;
  display: flex;
  justify-content: space-between;
}

.container-title-tabs > h3 {
  margin-bottom: 0 !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
  font-size: 1.4rem !important;
  color: #001775 !important;
}

.container-input-form {
  display: flex;
  justify-content: center;
}

.container-input-form .form-group {
  width: 50%;
  padding: 1%;
}

.container-input-form .container-group {
  width: 50%;
  padding: 1%;
}

.container-check-form {
  display: flex;
  padding: 1%;
}

.text-red {
  color: red;
  font-weight: 600;
}
.bg-red {
  background-color: rgb(240, 58, 58) !important;
}
.bg-green {
  background-color: rgb(75, 184, 75) !important;
}
.container-check-form > label {
  margin-left: 5px;
  font-size: 14px;
  margin-top: 2px;
}

.container-text-form {
  padding: 1%;
}

.button-form {
  padding: 1%;
}

.form-control-one {
  display: flex;
  padding-top: 2%;
  padding-bottom: 30px;
  justify-content: space-around;
}

.form-control-one > input {
  width: 47%;
}

.container-price-list-search {
  display: flex;
  justify-content: flex-end;
}

.input-group-append {
  margin-left: -6px !important;
}

.container-price-list-search > div > div > input {
  margin-top: 1% !important;
}

.container-multi-price {
  padding: 1%;
}

.container-file-import {
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
}

.container-form-import-list {
  display: flex;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
}

.container-form-import-list .container-group {
  width: 49%;
}

.container-form-import-list .form-group {
  width: 49%;
}

.container-cache-delete {
  padding: 1%;
}

.btn-secondary {
  background-color: #8a909d !important;
}

.container-search-user {
  width: 93%;
}

.container-search-customers {
  display: flex;
  justify-content: center;
}

.container-search-customers .form-group {
  width: 24%;
  padding: 1%;
}

#primary-content > div > div > div > div > div:nth-child(3) > div:nth-child(4) {
  margin-top: 1.4%;
}

#primary-content > div > div > div > div > div:nth-child(3) {
  margin-top: -2%;
}

.search-producy .container-group {
  width: 24%;
  padding: 1%;
}

.search-producy .form-group {
  width: 24%;
  padding: 1%;
  margin-top: -1.5%;
}

.form-customer {
  padding: 3%;
}

.nav-tabs .nav-link.active {
  color: #1853d6 !important;
  font-weight: 500 !important;
  background-color: #f5f6fa !important;
  border-color: #dee2e6 #dee2e6 #f5f6fa !important;
}

.nav-tabs {
  background-color: #fff !important;
}

.nav-tabs > a {
  color: #000000 !important;
  font-weight: 500 !important;
  padding: 0.75rem 1.25rem !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
}

.container-header-title-customer {
  padding-left: 1%;
  padding-right: 1%;
}

.container-search-customers .container-group {
  width: 24%;
  padding: 1%;
  align-self: center;
  margin-bottom: -8px;
}

#primary-content > div > div > div > div > div:nth-child(3) > div:nth-child(4) {
  display: flex;
  margin-top: 1%;
}

#primary-content > div > div > div > div > div:nth-child(3) > div:nth-child(4) > div > label:nth-child(2) {
  margin-right: 15%;
}

.container-search-contact {
  display: flex;
  justify-content: center;
  padding-top: 2%;
}

.container-search-contact .form-group {
  width: 100%;
  padding: 1%;
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-table-all
  > table {
  width: 98% !important;
}

.container-search-did {
  padding: 1%;
  display: flex;
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-search-did
  > select {
  width: 48% !important;
  align-self: center !important;
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-search-did
  > div {
  width: 48% !important;
  margin-bottom: 23px;
}

.container-search-did {
  justify-content: space-between !important;
}

.container-search-diallers {
  justify-content: space-between !important;
  display: flex;
}

.container-search-pbx .form-group {
  width: 32%;
}

.container-search-pbx .container-group {
  width: 32%;
}

.container-search-pbx-two {
  padding: 1%;
}

.container-search-pbx-button {
  padding: 1%;
}

.container-search-pbx-one {
  justify-content: space-between;
  display: flex;
  padding: 1%;
}

.container-search-diallers .form-group {
  width: 32%;
}

.badge {
  border-radius: 100px !important;
  line-height: unset !important;
  display: inline;
}

#primary-content > div.tab-content > div.tab-pane.active > div > div > div > div > div > div.card-body > table {
  width: 100% !important;
}

#primary-content > div.tab-content > div.tab-pane.active > div > div > div.card-body > table {
  width: 100% !important;
}

#primary-content > div.tab-content > div.tab-pane.active > div > div > table {
  width: 100% !important;
}

.container-loader {
  display: flex;
  justify-content: center;
  height: 750px;
}

.style-text-edit {
  color: #6b6666;
}

button {
  text-transform: initial !important;
}

#primary-content > div.tab-wrapper > div > div > div > div > div.fade.tab-pane.active.show > table {
  width: 100% !important;
}

#primary-content > div.tab-wrapper > div > div > div > div > div.fade.tab-pane.active.show {
  padding: 2% !important;
}

.container-check-box-agents {
  display: flex !important;
  padding: 1%;
}

.container-check-box-agents input {
  margin-right: 13px;
}

body > div:nth-child(10) > div > div.modal.fade.show > div > div > div.modal-body > h3 {
  margin-bottom: 19px;
}

body > div:nth-child(9) > div > div.modal.fade.show > div > div > div.modal-body > div.position-relative.form-check {
  display: flex !important;
}

body
  > div:nth-child(9)
  > div
  > div.modal.fade.show
  > div
  > div
  > div.modal-body
  > div.position-relative.form-check
  > input {
  margin-left: 10px;
}

.container-form-agents {
  padding: 2%;
}

.container-dropdown-custom {
  padding: 2%;
  width: 230px;
}

#page-content > header > div > nav > div.collapse.navbar-collapse > ul > div > div {
  transform: translate(-150px, 49px) !important;
}

.container-name-lo-out {
  padding: 4%;
}

.container-lo-out {
  padding-top: 5%;
  padding-left: 1%;
}

.d-inline-block-name {
  border-bottom: solid 1px #d4d4d6;
}

.span-emails {
  color: #8a909d !important;
}

.container-lo-out > a {
  color: #8a909d !important;
}

.span-name {
  color: #1b223c !important;
}

#dropdown-custom-components {
  background-color: #fff !important;
  color: #000;
  border: none;
  box-shadow: none !important;
}

.dropdown-toggle:focus {
  outline: none !important;
}

.container-search-prefix {
  display: flex;
}

.container-principali-search {
  display: flex;
  justify-content: space-between;
  width: 72%;
  margin-left: 3%;
}

.container-principali-search .form-group {
  width: 48%;
}

.container-button-search-prefix {
  align-self: center;
  margin-bottom: 3px;
  margin-left: 2%;
}

.container-agents-search {
  padding: 2%;
  display: flex;
  justify-content: space-between;
}

#primary-content
  > div
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div
  > div
  > div
  > div
  > div
  > div
  > div.container-agents-search
  > div
  > div {
  display: flex;
  align-items: center;
}

#primary-content
  > div
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div
  > div
  > div
  > div
  > div
  > div
  > div.container-agents-search
  > a {
  align-self: flex-end;
}

#primary-content > div > div > div > div {
}

#dropdown-basic-button {
  height: 66%;
  margin-top: 7%;
}

.search-descriptions-price {
}

.container-relleno {
  width: 3%;
}

.logo-container-principali {
  border-bottom: solid 1px #d2cfcf;
}

.logo-container-principali > img {
  max-width: 48%;
}

.logo-container-principali {
  height: 59px;
  text-align: justify;
  padding: 2%;
}

.container-input .form-group {
  display: flex;
  justify-content: center;
}

.container-input .form-group > input {
  width: 93% !important;
}

.container-text-login {
  height: 10px;
  text-align: justify;
  margin-left: 3%;
}

.search-descriptions-price .form-group {
  display: flex;
  margin-top: 7%;
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-search-did
  > div
  > div {
  width: 212% !important;
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-search-did
  > div
  > div
  > div:nth-child(2)
  > div
  > table
  > thead {
  color: #000;
  background-color: #fff;
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-search-did
  > div
  > div
  > div
  > div
  > div.col-lg-4
  > div {
  margin-top: 10% !important;
}

.container-search-voip {
  // display: flex;
  // padding: 1%;
}

.container-search-voip .form-control {
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-search-voip
  > div:nth-child(2) {
  width: 25% !important;
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-search-voip
  > div:nth-child(3) {
  width: 25% !important;
}

#primary-content
  > div.tab-wrapper
  > div
  > div
  > div
  > div
  > div.fade.tab-pane.active.show
  > div.container-search-voip
  > div:nth-child(4) {
  width: 25% !important;
}

.chekc-success {
  background-color: #b4e0be !important;
}
.chekc-success-0 {
  background-color: #b4e0be !important;
}
.chekc-success-1 {
  background-color: #b4e0be !important;
}
.chekc-success-2 {
  background-color: #b4e0be !important;
}
.chekc-success-3 {
  background-color: #b4e0be !important;
}
.chekc-success-4 {
  background-color: #b4e0be !important;
}
.chekc-success-5 {
  background-color: #b4e0be !important;
}
.chekc-success-6 {
  background-color: #b4e0be !important;
}
.chekc-success-7 {
  background-color: #b4e0be !important;
}
.chekc-success-8 {
  background-color: #b4e0be !important;
}
.chekc-success-9 {
  background-color: #b4e0be !important;
}

/////////////////**********////////////////////////////////

@media screen and (min-width: 992px) {
  .side-menu-collapsed .app-sidebar {
    width: 79px !important;
  }

  .side-menu-collapsed ul#main-menu li.nav-item a i {
    margin-left: 20% !important;
    transition: none !important;
    -webkit-transition: none !important;
  }

  .side-menu-collapsed ul#main-menu li.nav-item a {
    width: 158%;
  }
}
.visto {
  height: '60px';
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox'] + label span {
    display: inline-flex;
    margin-top: 15px;
    width: 25px;
    height: 25px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABhUlEQVRIx+3UvU+UQRAG8B8gV0Cj2BmtFL8wBhMTiZ0a/yKNscTKI7EhSgOo/wkBo9BgjDExioVncVAhBGyUj6N4x9ybYxdfbGh4ki125pnZ3WdnhmMcNboqcE7gBq5jIGyr+IQP2P7fwy9iAutoZdYaXmDwMC/oxRM8QC1sn/Eey7E/g5u4Evs/eIZRbB106wHMxe22MFVKksJVvAyZWpjFqRy5HwtB/I5bh5BzBI2IfYe+FOlVEL6GBGXUMBYSNVEvyfcXZ7EUOaY7k98PxwYuJw6v2//B9Yxkm+G/W3a8CeOjjATLiQNWMtzH4Z+D7g5ndyaolbDtZLjJ3roXSTalqyYl0dMEbwi/sIs7nc7pCFxSfFgZtTikKf/J5/AtckymXtGH+SA0FKVXFbfxI2LfypQpRZPMBnFb0URDByS+htfajTaDk//6kF5Fyz8syfAFi4pq6tIeFZfC/1t7VFQefhfwXDHQcsPuJ8ZxPpekyrjuwbBiXJ8O2yo+xtqpkOMYR4g9QwRyVSLb5KsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDQtMDdUMDg6NTU6MzgrMDA6MDB/OApKAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTA0LTA3VDA4OjU1OjM4KzAwOjAwDmWy9gAAAABJRU5ErkJggg==)
      center no-repeat;
    cursor: pointer;
  }
  input[type='checkbox']:checked + label span {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABdUlEQVRIx+3UPUsdQRTG8Z+5avANNSHfQCtBTC+iJimT+HlsUgkWYqOViGAjBLsr2GihXgxCCoUE8gkEEYJlFBK0uEdY1pn1ilaSB5bdnTnn/JnzMvzXAzSBbfQ8ZdC2wncdH7GLT/gT6y/wFiN4E/+/8QPH+NsqrAs7uI53V6yvxFrqucAyhh8D6cBmBeQaV5gL20q9QqPgeAtpx3jUpweTkdIyaA+DueDd+J5wKqarrNmE/WHEuqP1hPFl4iSLmEdn+G0l/FbLwT9kcvs5UZMaNgICUxnf6SKgkTHqj6CXCchC+PZlfPeLgIOMUW/snyZqUrsH0CgC3mWMJmN/vqLwE62kSBSmbFSPvc6AnGYK/7Xkt5bqom58S0BmS3apYSxCjuTb2qDmsJQhW9EtvRjATOYkX2K/Uh2aY3+VyW3qqRrGrIawpHmh5QKf4WcVpK0FUA1jGMVr/MM5fuEELzWb4b27V/2TqVj4+iNjVUJuT/KMdAO3eMA7ZfKdgwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wNC0wN1QwODo1NjoyNCswMDowMJ8F26MAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDQtMDdUMDg6NTY6MjQrMDA6MDDuWGMfAAAAAElFTkSuQmCC)
      center no-repeat;
  }
}

.app {
  transition: none !important;
  -webkit-transition: none !important;
}
.collapsing {
  transition: none !important;
}

.bg-fucsia {
  background-color: rgb(154, 95, 209);
}

.bg-salmon {
  background-color: darksalmon;
}

.table-customer {
  width: 94%;
}

.controlBalance-White {
  background-color: #f8f9fa !important;
}
.controlBalance-Blue {
  background-color: #0288d1 !important;
}
.controlBalance-Green {
  background-color: #388e3c !important;
}
.controlBalance-Orange {
  background-color: #f57c00 !important;
}
.controlBalance-Yellow {
  background-color: #fdd835 !important;
}
.controlBalance-Red {
  background-color: #e53935 !important;
}
.controlBalance-Gray {
  background-color: rgba(158, 149, 149, 0.479) !important;
}

.react-trello-board {
  background-color: white;
}
