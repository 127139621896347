/* PAGE STYLES
   ----------------------------- */

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#page-content {
  width: 100%;
  position: relative;
  margin-left: $sidebarWidth;

  &.has-alert {
    margin-top: 48px;
  }
}
#primary-content {
  padding: $pageContentPadding;
}
.page-container {
  width: 100%;
  margin: auto;
  max-width: 1170px;
}
.container {
  width: 100%;
  max-width: 1000px;
  padding-left: 0;
  padding-right: 0;
}
.app-body {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
}
.content-wrap {
  background-color: #ffffff;
  padding: 32px;
  border-radius: 3px;
  @include cardShadow;
}
.full-bleed-content {
  margin: -$pageContentPadding;
}
.narrow-page-container {
  max-width: 1000px;
  margin: auto;
}

// Responsive

//collapsed side menu
@media screen and (min-width: $breakPointSm) {
  .side-menu-collapsed {
    // z-index: 0;
    // transition: none !important;
    // -webkit-transition: none !important;
    #page-content {
      margin-left: 50px;
    }
  }
}
@media screen and (max-width: $breakPointSm) {
  #page-content {
    margin-left: 0px;
    margin-top: 50px;
  }
}
@media screen and (max-width: $breakPointXs) {
  #page-content {
    margin-top: 50px;
  }
}
